import React from 'react'
import {
    Box,
    // Container,
    Link,
    HStack,
    Container,
    Wrap,
    WrapItem
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
const slugify = require('slugify')

const TagsList = ({ tags }) => {
    return(
        <Wrap maxW='1030px' mx='auto' py='1rem' px={{ base:'1rem', lg:0 }}>
            {/* <pre>
                { JSON.stringify( tags , null, 2 )}
            </pre> */}
                { tags.map( tag =>
                    <WrapItem>
                        <Link
                            borderBottom='solid 1px'
                            borderColor='gray.300'
                            as={GatsbyLink}
                            p={1}
                            to={ `/blog/${slugify(tag.toLowerCase())}` }
                            _hover={{
                                textDecoration:'none',
                                bgColor:'gray.50'
                            }}
                        >
                            { tag.slice(0,1).toUpperCase() }{ tag.slice(1,100) }
                        </Link>
                    </WrapItem>
                )}
            {/* <pre>{ JSON.stringify(tags , null, 4 ) }</pre> */}
        </Wrap>
    )
}

export default TagsList