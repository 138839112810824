import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import SliceZone from '../components/SliceZone'
import { RichText } from 'prismic-reactjs'
import { Heading, HStack } from '@chakra-ui/react'
import TagsList from '../components/TagsList'

const Page = ({ data, pageContext }) => {
  const pageContent = data.prismic.post.edges[0]
  if (!pageContent) return null
  const page = pageContent.node

  const topMenuContent = data.prismic.allTop_menus.edges[0]
  if (!topMenuContent) return null
  const topMenu = topMenuContent.node

  const removeCurrentPost = (posts) => {
    let postsFiltered = []
    for (let index = 0; index < posts.length; index++) {
      var post = posts[index];
      if (post.node._meta.uid === page._meta.uid) { continue }
      else { postsFiltered.push(post) }
    }
    return postsFiltered
  }

  if (page) {
    useEffect(() => {
      const script = document.createElement('script');

      script.innerHTML = "!(function(w,d,s,l,x){w[l]=w[l]||[];w[l].t=w[l].t||new Date().getTime();console.log('1');var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.id='1';j.src='//web.tolstoycomments.com/sitejs/app.js?i='+l+'&x='+x+'&t='+w[l].t;f.parentNode.insertBefore(j,f);})(window,document,'script','tolstoycomments','3463');";

      document.head.appendChild(script);

      return () => {
        window.tolstoycomments.widget.destroy()
        window['tolstoycomments'] = undefined
        document.head.removeChild(document.getElementById('1'));
        document.head.removeChild(script);
      }
    }, []);


    useEffect(() => {
      window['tolstoycomments'] = window['tolstoycomments'] || [];
      window['tolstoycomments'].push({
        action: 'init',
        values: {
          visible: true,
          lang: 'en-US'
        }
      })
    }, [])

    return (
      <Layout
        topMenu={topMenu}
        activeDocMeta={page._meta}
        lang={pageContext.langIso}
        color="light"
      >
        <Helmet>
          {page.seo_title && <title>{RichText.asText(page.seo_title)}</title>}
          {page.seo_title && <meta name="og:title" content={RichText.asText(page.seo_title)} />}
          {page.seo_description && <meta name="description" content={RichText.asText(page.seo_description)} />}
          {page.sharing_image ?
            <meta property="og:image" content={page.sharing_image.url} />
            : null}
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          <meta name="twitter:title" content={ page.seo_title[0].text }></meta>
          <meta name="twitter:description" content={ page.seo_description[0].text }></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@malkin_vitaly"></meta>
          <meta name="twitter:creator" content="@malkin_vitaly"></meta>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
            `,
            }}
          />
        </Helmet>
        {page.title ?
          <Heading
            maxW="1030px"
            mx="auto"
            px={{ base: '1rem', lg: 0 }}
            py='2rem'
            as="h1"
            fontSize={{ base: '28px', lg: '36px' }}
            fontFamily='Vesper Libre'
          >
            {RichText.asText(page.title)}
          </Heading>
          : null}
          <TagsList tags= { data.newPost.tags } />
        <SliceZone slices={page.body} posts={removeCurrentPost(data.prismic.allPosts.edges)} />
        <div className="tolstoycomments-feed" />
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: `

        `}} />
      </Layout>
    )
  }
  return null
}

export const query = graphql`
query postQuery($uid: String, $lang: String) {

  newPost: prismicPost( uid: { eq: $uid } ){
    
    uid
    lang
    alternate_languages{
      uid
      id
      lang
      type
      uid
    }
    tags
    data{
      title{ text }
      seo_title{ text }
      seo_description{ text }
      sharing_image{ fixed{ src }}
    }


  }

  prismic {
    post:allPosts(uid: $uid, lang: $lang) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          title
          seo_title
          seo_description
          sharing_image
          body {
            ... on PRISMIC_PostBodyYoutube_player {
              type
              primary{
                video
              }
            }
            ... on PRISMIC_PostBodyBooks_list {
              label
              type
            }
            ... on PRISMIC_PostBodyLatest_posts {
              type
              label
              primary{
                display_thumbnail
              }
            }
            ... on PRISMIC_PostBodyRelatedentryblock {
              type
              label
              primary{
                title1
                teasing
                link {
                  ... on PRISMIC_Book{ sharing_image }
                  ... on PRISMIC_Post{ sharing_image }
                  ... on PRISMIC_Project{ sharing_image }
                  ... on PRISMIC_Commitment{ sharing_image }
                  ... on PRISMIC__Document {
                    _meta {
                      uid
                      type
                      lang
                    }
                  }
                  ... on PRISMIC__ExternalLink{
                    url
                  }
                }
              }
            }
            ... on PRISMIC_PostBodyFull_width_image {
              type
              label
              primary {
                image
                legend
              }
            }
            ... on PRISMIC_PostBodyText {
              type
              label
              primary{
                text
              }
            }
          }
        }
      }
    }
    allPosts(
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges {
        node {
          _meta {
            id
            uid
            type
            lang
            alternateLanguages {
              id
              lang
              type
              uid
            }
          }
          title
          featured_image
          is_visible
        }
      }
    }
    allBooks (
      lang: $lang
      sortBy:meta_firstPublicationDate_DESC
    ) {
      edges{
        node{
          _meta{
            id
            uid
            type
            lang
          }
          title
          intro
          cover
        }
      }
    }
    allTop_menus(lang: $lang) {
      edges {
        node {
          menu_links {
            label
            link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Post {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Archive {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
              ... on PRISMIC_Homepage {
                _meta {
                  uid
                  lang
                  type
                  alternateLanguages {
                    uid
                    lang
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

`

export default Page
